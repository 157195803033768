import Service from '@ember/service';
import window from 'ember-window-mock';

/* eslint-disable max-params,prefer-let/prefer-let,unicorn/prevent-abbreviations */
export default class UserGuidingService extends Service {
  initUserGuiding(userGuidingModel) {
    (function (g, u, i, d, e, s) {
      g[e] = g[e] || [];
      var f = u.getElementsByTagName(i)[0];
      var k = u.createElement(i);
      k.async = true;
      k.src = `https://static.userguiding.com/media/user-guiding-${s}-embedded.js`;
      f.parentNode.insertBefore(k, f);
      if (g[d]) {
        return;
      }
      var ug = (g[d] = { q: [] });
      ug.c = function (n) {
        return function () {
          ug.q.push([n, arguments]);
        };
      };
      var m = ['previewGuide', 'finishPreview', 'track', 'identify', 'hideChecklist', 'launchChecklist'];
      for (let element of m) {
        ug[element] = ug.c(element);
      }
    })(window, document, 'script', 'userGuiding', 'userGuidingLayer', 'N3R87687465ID');
    window.userGuiding.identify(userGuidingModel.userId, {
      userType: userGuidingModel.userType,
      premiumStatus: userGuidingModel.premiumStatus,
      userRole: userGuidingModel.userRole,
      erp_setup: userGuidingModel.erpQualifier ?? '',
      book_groups:
        userGuidingModel.associatedBookGroups || userGuidingModel.associatedBookGroups?.length === 0
          ? ''
          : userGuidingModel.associatedBookGroups?.join(';'),
      thalia_hug: userGuidingModel.thaliaHug ?? '',
      additional_right_web_print: userGuidingModel.additionalRightWebToPrint,
    });
  }
}
/* eslint-enable */
